.hover:hover{
    background-color: gray;
    color:white;
    /* cursor: pointer; */
}
.hover .sticky-column {
  background-color: #ffff;
  position: sticky;
  left: 0;
  z-index: 10;
}

body[data-theme="dark"] {
  .hover .sticky-column {
    background-color: #1a2942;
  }
  .hover:hover .sticky-column {
    background-color: #202936;
  }
}

.hover:hover .sticky-column {
  background-color: gray;
  color:white;
}
.hover1:hover{
    color: black;
    /* cursor: pointer; */
}


/* .table-responsive2 {
    max-height: 550px; 
    overflow-y: auto;
  }
  
  .sticky-header th {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #ffffff; 
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.1); 
  } */

/* Card header container */
.card-header {
  position: relative; /* Ensure dropdown is positioned relative to this container */
  z-index: 15; /* Set higher than table header */
}

/* Table container with scroll */
.table-responsive2 {
  max-height: 550px;
  overflow-y: auto;
}

/* Sticky table header */
thead th {
  position: sticky;
  top: 0;
  z-index: 10; /* Lower z-index than dropdown */
  background-color: #ffffff;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.1);
}

/* Dropdown styling inside the card header */
.card-header .dropdown-menu {
  display: none;
  position: absolute;
  top: 100%; /* Position dropdown below the trigger element */
  left: 0;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 20; /* Higher than table header */
}

/* Show dropdown on hover or focus within */
.card-header .dropdown:hover .dropdown-menu,
.card-header .dropdown:focus-within .dropdown-menu {
  display: block;
}

.sticky-header th {
  position: sticky;
  top: 0;
  z-index: 2;
}