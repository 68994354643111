.change-button {
  border-radius: 1rem;
  box-shadow: none;
  border-style: none;
  background-color: inherit;
  filter: brightness(80%);
}

.card-css {
  padding: 0.625rem 1.25rem !important;
}

.score-button {
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.999);
  color: black !important;
  transition: filter 0.3s;
  margin: 0 !important;
  padding: 0 !important;
  border-style: solid;
  border-color: rgba(143, 226, 253, 0.588);
  border-width: 1px;
}

.color-out {
  background-color: rgba(255, 160, 160, 0.47);
  border-color: rgba(251, 138, 109, 0.727);
}

.score-button:hover {
  filter: brightness(95%);
  /* background-color: green !important; */
}

.color-red {
  background-color: rgb(241, 89, 89) !important;
}

.event-name-header {
  font-size: 26px;
  font-weight: bolder;
  background-color: rgb(58, 199, 255);
  padding: 0.3rem;
  color: white;
}

.team-name {
  font-weight: bold;
  /* color: white; */
  text-align: center;
  /* padding: 0.4rem; */
  font-size: 22px;
}

.team-1 {
  background-color: rgba(253, 206, 117, 0.473);
  color: darkgoldenrod;
}

.overs-header {
  background-color: rgba(117, 219, 253, 0.473);
  color: rgb(11, 112, 184);
  /* padding: 0px; */
  /* margin: 0px; */
}

.team-2 {
  background-color: #64ff1689;
  color: darkgreen;
}

.current-score-header {
  text-align: center;
  /* background-color: rgba(168, 224, 243, 0.47); */
  padding: 0.5rem;
}

.current-team-name {
  font-size: 30px;
  font-weight: bolder;
}

.current-team-score {
  font-size: 26px;
}

.striker-end {
  /* text-align: center; */
  background-color: rgba(247, 189, 81, 0.798);
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 0.25rem !important;
}

.non-striker-end {
  /* text-align: center; */
  background-color: rgba(253, 206, 117, 0.473);
  font-size: 16px;
  /* font-weight: bold; */
  letter-spacing: 1px;
  padding: 0.25rem !important;
}

.Bowler-header > div {
  font-size: 16px;
  padding: 0.25rem !important;
  background-color: lightgreen !important;
}

.text-right {
  float: right;
}

.button-icon {
  height: 40%;
}

.button-icon-lg {
  height: 75%;
}

.decision-Button {
  min-width: 5rem;
}

.commentary-modal {
  margin-top: 80px !important;
  max-height: 90vh !important;
}

.wicket-section-header {
  font-size: 18px;
  font-weight: bold;
}

.modal-header-style {
  font-size: 22px;
  font-weight: bolder;
}

.bowling-team-score-header {
  text-align: center;
  /* background-color: rgba(142, 169, 178, 0.47); */
  padding: 0.5rem;
}

.bowling-team-name {
  font-size: 30px;
  color: rgba(199, 196, 196, 0.415);
  /* font-weight: bolder; */
}

.bowling-team-score {
  color: rgba(199, 196, 196, 0.415);
  font-size: 26px;
}

.match-details-breadcrumbs {
  font-size: 16px;
}

.disable-button {
  pointer-events: none;
  opacity: 0.5;
}

.switch-padding {
  padding: 0 4rem !important;
}

.normal-header {
  font-weight: 100 !important;
}

.over-ball-display {
  min-width: 7%;
  border-radius: 0.2rem;
  margin: 0%;
  padding: 0 2%;
}

.ball-blue {
  background-color: skyblue;
}

.ball-white {
  background-color: white;
}

/* .color-white {
  background-color: white !important;
} */

.ball-red {
  background-color: red;
}

#addreloadicon {
  max-width: 126px;
}

.sticky-button {
  height: 24px;
  /* position: fixed; */
  /* right: 3%; */
  /* bottom: 10%; */
  cursor: pointer;
}
.sticky-btn {
  /* height: 24px; */
  position: fixed; 
  right: 3%;
  bottom: 10%;
  cursor: pointer;
  z-index: 99;
}

.player-header,
.section-header {
  background: lightgray;
  padding: 0.2rem;
  margin: 0 0 1rem 0;
  font-size: large;
  font-weight: 600;
}

.header-section {
  padding: 0.2rem;
  margin: 0 0 1rem 0;
  font-size: large;
  font-weight: 600;
}

.table-Row-dark {
  background: lightgray;
  padding: 0.2rem;
  font-size: large;
  font-weight: 600;
}

.table-Row-light {
  padding: 0.2rem;
  font-size: large;
  font-weight: 600;
}

.generate-team-button {
  margin: 2rem 0 0 0;
  float: right;
}

.small-text-fields {
  padding: 0 0.3rem !important;
  min-width: 3.5rem !important;
  line-height: 26px !important;
  font-size: 12px !important;
}

.price-text-fields {
  padding: 0 0.3rem !important;
  min-width: 3.5rem !important;
  line-height: 26px !important;
  font-size: 14px !important;
}

.size-text-fields {
  padding: 0 0.3rem !important;
  min-width: 3.5rem !important;
  line-height: 26px !important;
  font-size: 11px !important;
}

.medium-text-fields {
  padding: 0 0.3rem !important;
  min-width: 8.5rem !important;
  line-height: 26px !important;
  font-size: 12px !important;
}

.line-text-fields {
  padding: 0 0.3rem !important;
  min-width: 1.8rem !important;
  line-height: 16px !important;
  font-size: 10px !important;
}

.line-center-text-fields {
  padding: 0 0.3rem !important;
  min-width: 2.5rem !important;
  line-height: 26px !important;
  font-size: 12px !important;
}

.small-labels {
  word-break: break-word;
  padding: 0;
  white-space: normal;
}

.small-label-div {
  width: 100%;
}

.small-button {
  padding: 0.2rem 0.5rem !important;
}

.table-header-button {
  margin: 1% 1%;
  width: 48% !important;
  padding: 0.2rem !important;
}

.table-header-button-2 {
  margin: 1% 1%;
  width: 30% !important;
  padding: 0.2rem !important;
}

@media (max-width: 600px) {
  .table-header-button-2 {
    width: 48% !important;
  }

  .table-header-button-2.table-header-button-3 {
    width: 100% !important;
  }
}

.btn-color-yellow {
  color: #60530b !important;
  background-color: #f4f481 !important;
  font-weight: 500;
  border-color: #60530b !important;
}

.btn-color-orange {
  color: #60400b !important;
  background-color: #ffc98f !important;
  font-weight: 500;
  border-color: #60400b !important;
}

.btn-color-purple {
  color: #8000ffe2 !important;
  background-color: #c089f7a4 !important;
  font-weight: 500;
  border-color: #60400b !important;
}

.feature-card-header {
  padding: 0.2rem;
  margin: 0 0 1rem 0;
  font-size: large;
  font-weight: 600;
  text-align: center;
}

.text-center {
  text-align: center;
}

.input-yes-field {
  background-color: #bbe1ee !important;
  color: #106481 !important;
}

.input-no-field {
  background-color: #f6c5c8 !important;
  color: #ad1119 !important;
}

.input-under-field {
  background-color: #f3e3e4 !important;
  color: #800d14 !important;
}

.input-over-field {
  background-color: #ddecf1 !important;
  color: #094155 !important;
}

.input-line-field {
  background-color: #e2f9c2 !important;
  color: #294503 !important;
}

.text-bold {
  font-weight: 600 !important;
}

.ball-by-ball-display {
  padding: 8px 0;
  display: flex !important;
  flex-wrap: wrap;
  gap: 10px;
}

.odds-page-header {
  text-align: center;
  font-size: large;
}

.odds-container {
  display: flex !important;
}

.odds-box {
  margin: 0 0.1rem;
  padding: 0.3rem;
  min-width: 25%;
  text-align: center;
  border-radius: 0.5rem;
  font-weight: 500;
}

.odds-column {
  padding: 0 !important;
}

.over-render {
  border-style: solid;
  border-top: none;
  border-color: transparent;
  padding: 0px;
  /* padding: 0 0 0 1rem; */
}

.section-info {
  font-size: medium !important;
  font-weight: 400 !important;
  font-style: italic !important;
}

.delete-item-button {
  padding: 0 0.3rem !important;
  width: 70% !important;
  height: 50%;
}

.background-nth {
  background-color: #f6f7fb;
}

.w-100 {
  width: 100% !important;
}

.live-css {
  color: green;
  width: 100;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  background: green;
  text-align: center;
  line-height: 10px;
}

.live-text {
  color: green;
  font-weight: bold;
}

.disconnected-css {
  color: #ff3d60;
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  background: #ff3d60;
  text-align: center;
  line-height: 10px;
}

.disconnected-text {
  color: #ff3d60;
  font-weight: bold;
}

.dls-button {
  background-color: rgb(172, 120, 251) !important;
  border-color: rgb(172, 120, 251) !important;
}

.award-button {
  background-color: rgb(255, 136, 223) !important;
  border-color: rgb(255, 136, 223) !important;
}

.red-warning-button {
  background-color: #f5d3d3;
  border-color: #f5d3d3;
}

.red-warning-modal > .modal-content {
  background-color: #f5d3d3;
  border-color: #f5d3d3;
}

.red-warning-modal > .modal-content > div {
  border-color: #f5d3d3;
}

.yellow-information-button {
  background-color: #f5f3d3;
  border-color: #f5f3d3;
}

.yellow-information-modal > .modal-content {
  background-color: #f5f3d3;
  border-color: #f5f3d3;
}

.yellow-information-modal > .modal-content > div {
  border-color: #f5f3d3;
}

.green-success-button {
  background-color: #e1fad8;
  border-color: #e1fad8;
}

.green-success-modal > .modal-content {
  background-color: #e1fad8;
  border-color: #e1fad8;
}

.green-success-modal > .modal-content > div {
  border-color: #e1fad8;
}

.bg-yellow {
  background-color: yellow;
}

.market-category-accordian {
  margin-top: 0.75rem;
  background-color: beige;
}

.market-category-header > button {
  text-transform: capitalize;
  padding: 0.2rem 1rem !important;
}

.market-category-body > div {
  padding: 0 !important;
}

.open-market-table-class {
  padding: 0 0 !important;
}

.color-light-grey {
  /* background-color: rgba(233, 233, 233, 0.594); */
}

.event-market-modal .modal-dialog {
  max-width: 90%;
  width: 90%;
  margin: 1.75rem auto;
}

.event-market-modal .modal-content {
  height: 90vh;
  overflow-y: auto;
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-right-10 {
  margin-right: 10px;
}

.custom-event-snap {
  text-align: right;
  width: 20%;
}

/* over model css */
.over-modal-container {
  max-width: 500px;
  margin: 1.75rem auto;
}

.over-modal-container .modal-content {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 1rem;
}

.over-modal-header {
  border: none;
  padding: 0;
}

.over-modal-title {
  color: #dc3545;
  font-size: 1.25rem;
  font-weight: 500;
}

.over-modal-body {
  padding: 1rem 0;
}

.over-stat {
  background: #6c757d;
  border-radius: 20px;
}

/* Stats Grid */
.over-modal-stats-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1px;
  background: #6c757d;
  margin-bottom: 1rem;
}

.over-modal-stat {
  background: #6c757d;
  padding: 0.5rem;
  text-align: center;
}

.over-modal-stat-value {
  color: white;
  font-size: 1.25rem;
  font-weight: 500;
}

.over-modal-stat-label {
  color: white;
  font-size: 0.875rem;
}

/* Ball Display */
.over-modal-info {
  margin: 1rem 0;
}

.over-modal-over-text {
  color: #6c757d;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.over-modal-balls-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.over-modal-ball {
  padding: 0.25rem 0.75rem;
  border-radius: 4px;
  font-size: 0.875rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.over-modal-normal {
  background: white;
  color: #212529;
}

.over-modal-wicket {
  background: #dc3545;
  color: white;
}

.over-modal-extra {
  background: #ffc107;
  color: #212529;
}

.over-modal-boundary {
  background: #28a745;
  color: white;
}

.over-modal-total {
  margin-left: auto;
  font-weight: 500;
}

/* Action Buttons */
.over-modal-actions {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
}

.over-modal-start-btn {
  background: #00a67e !important;
  border: none;
  color: white;
  padding: 0.75rem;
  font-weight: 500;
  width: 100%;
}

.over-modal-continue-btn {
  border: none;
  color: red !important;
  padding: 0.75rem;
  font-weight: 500;
  width: 100%;
}

.over-modal-start-btn:hover {
  background: #009472 !important;
}

.over-modal-continue-btn:hover {
  background: rgba(220, 53, 69, 0.1) !important;
  color: red;
}

.over-modal-player-stats {
  margin: 20px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
}

.over-modal-player-row {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  gap: 10px;
  padding: 8px 0;
  border-bottom: 1px solid #eee;
}

.over-modal-player-row:last-child {
  border-bottom: none;
}

.over-modal-player-name {
  font-weight: 500;
}

.over-modal-player-stat {
  text-align: center;
}

.over-modal-header-row {
  font-weight: 600;
  background-color: #f5f5f5;
  border-bottom: 2px solid #ddd;
}

/* wicket model css */
.wicket-modal {
  position: fixed;
  top: 10%;
  transform: translate(-50%, -50%);
  max-width: 80vw;
  width: 1200px;
  max-height: 90vh;
  margin: 0;
  z-index: 90 !important;
}

.wicket-modal-header {
  font-size: 1.25rem;
  font-weight: 600;
  color: #2c3e50;
  padding: 0.75rem;
}

.wicket-modal-body {
  padding: 0.75rem;
}

.wicket-modal-summary {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 0.5rem;
}

.wicket-section-header {
  font-size: 1rem;
  font-weight: 500;
  color: #2c3e50;
  margin-bottom: 0.5rem;
}

.wicket-modal-player-row {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;
  /* padding: 8px 0; */
  /* border-bottom: 1px solid #eee; */
}

.wicket-modal-header-row {
  font-weight: 500;
  color: #6b6b6b;
  /* background-color: #f5f5f5; */
  /* border-bottom: 2px solid #ddd; */
}

.wicket-modal-player-name {
  font-weight: 500 !important;
}

.wicket-modal-player-stat {
  text-align: center;
}

.wicket-modal-player-card:hover {
  transform: translateY(-2px);
}

.wicket-modal-card-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #e9ecef;
  padding-bottom: 0.25rem;
}

.wicket-modal-stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
  gap: 0.75rem;
  padding: 0.5rem;
}

.wicket-modal-stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: #f8f9fa;
  border-radius: 6px;
  padding: 0.5rem;
}

.wicket-modal-stat-item:hover {
  background: #e9ecef;
}

.wicket-modal-stat-label {
  font-size: 0.75rem;
  color: #6c757d;
  margin-bottom: 0.25rem;
  white-space: nowrap;
}

.wicket-modal-stat-value {
  font-size: 1rem;
  font-weight: 600;
  color: #2c3e50;
}

.wicket-modal-bowler-card {
  background: #f8f9fa;
  border: 1px solid #e9ecef;
}

.wicket-modal-footer {
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
}

.wicket-modal-actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.wicket-modal-next-btn,
.wicket-modal-confirm-btn,
.wicket-modal-back-btn {
  min-width: 100px;
  font-weight: 500;
  padding: 0.5rem 1rem;
}

.wicket-modal-next-btn,
.wicket-modal-confirm-btn {
  background-color: #099680;
  border-color: #099680;
}

.wicket-modal-next-btn:hover,
.wicket-modal-confirm-btn:hover {
  background-color: #077f6c;
  border-color: #077f6c;
}

@media (max-width: 768px) {
  .wicket-modal {
    max-width: 95vw;
    width: auto;
  }

  .wicket-modal-stats-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .wicket-modal-player-card {
    padding: 0.5rem;
    min-height: 180px;
  }

  .wicket-modal-stat-item {
    padding: 0.4rem;
  }
}

/* partnership changes */
/* Add these styles to your CommentaryCss.css file */

.partnership-tab-navigation {
  display: flex;
  /* gap: 1rem; */
  justify-content: space-between;
  /* border-bottom: 1px solid #ddd;
    padding-bottom: 0.5rem; */
}

.partnership-tab-navigation .tab-button {
  padding: 0.5rem 1rem;
  border: none;
  background: none;
  cursor: pointer;
  font-weight: 500;
  color: #666;
  transition: all 0.3s ease;
}

.partnership-tab-navigation .tab-button.active {
  color: #0056b3;
  border-bottom: 2px solid #0056b3;
}

.first-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: white;
}

.first-card-header {
  background: #f8f9fa;
  padding: 0.5rem;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  text-align: center;
}

.first-card-body {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.remaining-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: white;
}

.remaining-card-header {
  background: #f8f9fa;
  padding: 0.5rem;
  /* font-weight: bold; */
  font-size: 0.7rem;
  border-bottom: 1px solid #ddd;
  text-align: center;
}

.remaining-card-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
}

.first-card-player-section {
  /* display: flex; */
  /* flex: 1; */
  /* align-items: center;
  text-align: center;
  padding: 0.5rem; */
}

.remaining-card-player-section {
  /* display: flex;
  justify-content: center; */
  /* flex: 1; */
  /* text-align: center;
  align-items: center;
  padding: 1rem 0.5rem; */
}

.first-player-image {
  background-color: #d8d8d8 !important;
  width: 60px;
  height: 60px;
  border-radius: 4px;
  object-fit: cover;
}

.remaining-player-image {
  width: 30px;
  height: 30px;
  background-color: #d8d8d8 !important;
  border-radius: 4px;
  object-fit: cover;
}

.fisrt-player-name {
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.remaining-player-name {
  margin-bottom: 0.25rem;
  font-size: 0.8rem;
}

.first-player-stats {
  color: #666;
  font-size: 0.9rem;
}

.remaining-player-stats {
  color: #666;
  font-size: 0.7rem;
}

.first-partnership-stats {
  flex: 1;
  text-align: center;
  padding: 0 1rem;
}

.remaining-partnership-stats {
  flex: 1;
  text-align: center;
}

.first-total-runs {
  font-size: 1.5rem;
  font-weight: bold;
  color: #0056b3;
}

.remaining-total-runs {
  font-weight: bold;
  /* color: #0056b3; */
}

.first-total-balls {
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.remaining-total-balls {
  color: #666;
  font-size: 0.7rem;
}

.first-partnership-extras {
  font-size: 0.8rem;
  color: #888;
}

.remaining-partnership-extras {
  font-size: 0.6rem;
  color: #888;
}

.partnerships-container {
  max-height: 500px;
  overflow-y: auto;
  /* padding: 1rem; */
}

.overs-accordion {
  font-family: "Work Sans", sans-serif !important;
}

.css-lhsbas-MuiTypography-root
  .css-1wle3ir-MuiTypography-root
  .css-5neixw-MuiTypography-root.css-1ypmsg6-MuiTypography-root {
  font-family: "Work Sans", sans-serif !important;
}

@media (max-width: 575.98px) {
  .first-player-name {
    font-size: 10px !important;
  }

  .table-responsive {
    overflow-x: hidden !important;
  }

  .first-player-stats {
    font-size: 10px !important;
  }

  .remaining-card-body {
    padding: 0px !important;
  }

  .first-card-header {
    font-size: 14px !important;
    padding: 0.4rem;
  }

  .first-total-runs {
    font-size: 16px !important;
  }

  .first-total-balls-span {
    font-size: 12px !important;
  }

  .first-total-balls {
    font-size: 8px !important;
  }

  .first-partnership-extras {
    font-size: 10px !important;
  }

  .scoring-row {
    margin: 0px !important;
    padding: 0px !important;
  }

  .partnerships-container {
    padding: 0px;
    margin: 0px;
  }

  .first-player-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-bottom: 0px !important;
    object-fit: cover;
  }

  .first-card-body {
    padding: 10px 0px !important;
  }

  .first-card-player-section {
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0.5rem;
  }

  .remaining-card-player-section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .remaining-player-name {
    font-size: 10px !important;
  }

  .remaining-total-balls {
    font-size: 8px !important;
  }

  .remaining-player-image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
  }

  .player-details {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .remaining-player-stats {
    font-size: 8px !important;
  }

  .circle-display {
    display: none;
  }

  .wicket-card-title {
    font-size: 10px !important;
  }

  .card-body {
    /* flex: 1 1 auto; */
    /* padding: 4px 6px !important; */
  }

  .card {
    margin-bottom: 8px !important;
    display: flex !important;
    justify-content: start !important;
  }
}

/* Ensure proper spacing between cards */
.partnership-card + .partnership-card {
  margin-top: 1rem;
}

/* .shot-types-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  padding: 20px;
} */

.market-template-list {
  max-height: 450px;
  overflow-y: auto;
}

.cricket-field {
  position: relative;
  width: 400px;
  height: 400px;
}

.cricket-field-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 400px;
  height: 400px;
}

.cricket-field-img-new {
 /* position: absolute; */
  /* top: 0; */
  /* left: 0; */
  width: 100%;
  /* height: 100%; */
}

.cricket-field-svg {
  position: absolute;
  top: 0;
  left: 0;
  cursor: crosshair;
  border-radius: 100%;
}

.cricket-field-svg-new {
  position: absolute;
  top: 0;
  left: 4px;
  cursor: crosshair;
  border-radius: 100%;
}

.playerNameScroll {
  display: flex !important;
  overflow-x: auto !important;
  white-space: nowrap !important;
  scrollbar-width: none !important;
}

.playerNameScroll:hover {
  cursor: pointer;
}

.playerNameScroll::-webkit-scrollbar {
  display: none;
  /* For Chrome, Safari, and newer Edge versions */
}

.no-rate {
  background-color: #850912 !important;
  color: #fff !important;
}

.rate-font {
  font-size: 16px;
  font-weight: 600;
}

.point-font {
  font-size: 12px;
}

.yes-rate {
  background-color: #0c5d78 !important;
  color: #fff !important;
}

.market-back-lay {
  width: 100px;
}

.runner-table {
  width: 50%;
}

.runner-button-commentary {
  color: #e289e8;
}

.ball-start::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #ff3d60;
  border-radius: 50%;
  animation: blink 1s infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.MuiBox-root .css-sccedh {
  padding: 5px 0px !important;
}

.load-commentary-btn {
  /* background-color: "#2c2c3e" !important;
    color: "#fff" !important;
    box-shadow: "0 4px 10px rgba(0, 0, 0, 0.3)" !important;
    width: "100%" !important;
    height: "100%" !important;
    font-weight: "bold" !important; */

  border: none !important;
  border-radius: "10px" !important;
  font-size: "8px" !important;
  color: #fff !important;
  padding: "2px 4px" !important;
  background-color: #2c2c3e !important;
}

.exit-btn {
  color: white;
  background-color: rgb(189, 16, 16);
}

/* new commentary screen */
.score-number-button {
  background-color: "#2c2c3e" !important;
  color: "#fff" !important;
  box-shadow: "0 4px 10px rgba(0, 0, 0, 0.3)" !important;
  border-radius: "50px" !important;
  width: "100%" !important;
  height: "100%" !important;
  font-size: "20px" !important;
  font-weight: "bold" !important;
}
.vertical-line {
  border-left: 2px solid #000; /* Adjust the color and thickness of the line */
  height: 100%; /* Ensure the line stretches across the full height */
}

.badge {
  /* position: "absolute";
  background: "blue";
  padding: "2px 26px";
  top: "-6px";
  right: "50%";
  font-size: "10px";
  transform: "translateX(50%)";
  border-radius: "0px 0px 60px 60px"; */
  color: #fff !important;
  position: "absolute" !important;
  top: "-6px" !important;
  right: "50%" !important;
}

.badge-parent{
    position: relative;
}

.card-container {
  position: relative;
  height: calc(100vh - 100px);
  overflow: hidden;
}

.header-sticky {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 2;
}

.content-section {
  overflow-y: auto;
  height: calc(100% - 170px);
  /* &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  /* scrollbar-width: none;   */
}

body[data-theme="dark"] {
  .header-sticky {
    background-color: #1a2942 !important;
  }
}