@media only screen and (max-width: 600px) {
    .dynamic-label-right {
        float: left !important;
    }

    .ImageDropBox {
        /* padding-left: 10px;
        margin-top: 20px; */
    }

    .form-label-class {
        margin-bottom: 0rem !important;
    }
}

@media only screen and (min-width: 768px) {
    .ck-content {
        min-height: 200px;
    }
}

@media only screen and (max-width: 767px) {
    .ck-content {
        min-height: 150px;
    }
}

.block {
    display: block;
}

.dynamic-label-right {
    float: right;
    white-space: nowrap;
}

.image-uploader-Event {
    width: 150px;
    /* Adjust the size as needed */
    height: 120px;
    /* This makes it a square box */
    border: 2px dashed #007bff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 10px;
}

.video-uploader {
    width: 150px;
    /* Adjust the size as needed */
    height: 120px;
    /* This makes it a square box */
    border: 2px dashed #007bff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 10px;
}

.preview-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.preview-video {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.file-input-EventImage-uploader {
    display: none;
    visibility: hidden;
}

.file-input-video-uploader {
    display: none;
    visibility: hidden;
}

.file-label-Event-Uploader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.upload-iconEventUploader {
    font-size: 48px;
    /* margin-bottom: 5px; */
}

.upload-icon-video-uploader {
    font-size: 48px;
    display: flex;
    justify-content: center;
    /* margin-bottom: 5px; */
}

/* Style the box when hovering over it */
.image-uploader-Event:hover {
    border-color: #007bff;
}

.video-uploader:hover {
    border-color: #007bff;
}

/* Style the box when a file is dragged over it */
.image-uploader-Event.dragover {
    border-color: #ff5722;
}

.video-uploader.dragover {
    border-color: #ff5722;
}

.UploadImageText {
    text-align: center;
    color: #a0a0a0;
}

.UploadVideoText {
    text-align: center;
    color: #a0a0a0;
}

.ImageDropBox {
    height: fit-content;
    /* padding-left: 170px; */
    /* display: flex;
    justify-content: center; */

}

.UploadImageText,
.upload-iconEventUploader {
    color: #908d8d;
    font-weight: bolder;
    /* padding: 5px; */
}

.UploadVideoText,
.upload-upload-icon-video-uploader {
    color: #908d8d;
    font-weight: bolder;
    /* padding: 5px; */
}


.form-label-class {
    white-space: break-spaces;
    word-break: break-all;
}

.editor-container {
    min-height: 300px;
    border: 1px solid #ccc;
}


/* Floating Button Css Start*/
.floating-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    /* High z-index */
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    /* z-index: 90; */
    /* Higher z-index */
    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 5px;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 24px;
    cursor: pointer;
}

/* Foating Button Css End */

.no-spinners::-webkit-inner-spin-button,
.no-spinners::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.no-spinners {
    -moz-appearance: textfield;
}

.whitespace-nowrap {
    white-space: nowrap !important;
}