.css-1nmdiq5-menu{
    z-index: 999 !important;
}

.padding-row {
    padding-bottom: 137px;
}

.tournament-team-name {
    margin-right: 10px;
    font-weight: 700;
}

.multi-dropdown-width {
    width: 200px !important
}

.date-width {
    width: 200px !important;
}
.label-width {
    width: 150px !important;
}
.label-price-width {
    width: 120px !important;
}
.font-bold {
    font-weight: 700;
}
.margin-right-10 {
    margin-right: 10px;
}
.margin-right-20 {
    margin-right: 20px;
}