body[data-theme="dark"]{
    .modal-content{
        background-color: $dark-boxed-body-bg !important;
        color: $dark-font-color !important;
    }
    .modal-header, .modal-body, .modal-title .normal-header{
        color: $dark-font-color !important;
    }
    .modal-header{
        h5{
            color: $dark-font-color !important;
        }
    }
    .col-12.col-md-6.col-lg-6 {
        color: $dark-font-color !important;
      }
    .wicket-modal-header{
        color: $dark-font-color !important;
    }
}