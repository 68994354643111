//
// _card.scss
//
.toss-card, .toss-card-header{
    background-color: $light-score-card-bg !important;
    color: $light-font-color !important;
}

.card {
    margin-bottom: $grid-gutter-width;
    box-shadow: $box-shadow;
}

.card-drop {
    font-size: 20px;
    line-height: 0;
    color: inherit;
}

.card-title {
    font-size: 15px;
    margin: 0 0 7px 0;
    font-weight: $font-weight-medium;
}

.card-title-desc {
    color: $card-title-desc;
    margin-bottom: 24px;
}

// card color variant

@mixin card-variant($bg) {
    color: $white;
    background-color: $bg;
}

@each $color, $value in $theme-colors {
    .card-#{$color} {
        @include card-variant($value);
        .card-header {
            background-color: rgba($white, 0.1);
            color: $white;
        }
        .card-title {
            color: $white;
        }
    }
}


// card border variant

@mixin card-border-variant($bg) {
    border: 1px solid $bg;

    .card-header {
        border-bottom: 1px solid $bg;
        background: transparent;
    }
    .card-footer {
        border-top: 1px solid $bg;
        background: transparent;
    }
}

@each $color, $value in $theme-colors {
    .card-border-#{$color} {
        @include card-border-variant($value);
    }
}

body[data-theme="dark"] {
    .toss-card, .toss-card-header{
        h2{
            color: $dark-font-color !important;
        }
        background-color: $dark-score-card-bg !important;
        color: $dark-font-color !important;
    }
    .toss-card-title {
        h4 {
            color: $dark-font-color !important;
        }
      }
    .card{
        background-color: $dark-boxed-body-bg;
    }
    .card-body{
        color: $dark-table-td-color;
    }
    .card-header{
        background-color: $dark-boxed-body-bg;
        color: $dark-table-td-color;
    }
}